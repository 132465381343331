import {
  Box,
  Flex,
  Text,
  Title,
} from '@huspy/briks-web';
import { useTranslation } from 'react-i18next';
import { authPageStyles } from './styles/index.css';
import Login from './widgets/Login';

const {
  desktopAuthenticationLeftStyles,
  desktopAuthenticationRightStyles,
  desktopWrapperAuthStyles,
} = authPageStyles;

export const PassAuthFlow = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Desktop View */}
      <Flex
        w='100%'
        minH='100%'
        className={ desktopWrapperAuthStyles }
        gap='8'
        data-testid='pass-auth-screen'
      >
        <Box className={ desktopAuthenticationLeftStyles }>
          <Box margin='auto'>
            <Title size='h3' color='base.white' fontWeight='medium'>
              { t('login.title') }
            </Title>
            <Text
              size='2xl'
              color='base.white'
              dangerouslySetInnerHTML={ { __html: t('login.subtitle') } }
            />
          </Box>
        </Box>
        <Flex w='100%' className={ desktopAuthenticationRightStyles } align='center'>
          <Login isDesktopView />
        </Flex>
      </Flex>
      {/* Mobile View */}
      <Flex
        className={ authPageStyles.mobileAuthenticationStyles }
        direction='column'
        justify='space-between'
      >
        <Box>
          <Title
            size='h3'
            color='base.white'
            fontWeight='medium'
          >
            { t('login.title') }
          </Title>
          <Text
            size='md'
            color='base.white'
            dangerouslySetInnerHTML={ { __html: t('login.subtitle') } }
          />
        </Box>
        <Login />
      </Flex>
    </>
  );
};

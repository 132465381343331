import { Checkbox } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import {
  CURRENCY,
  EMPLOYMENT_STATUS,
  INCOME_FREQUENCY,
  INCOME_TYPE,
} from '@modules/kyc/api/const';
import { Box, Flex } from '@huspy/briks-web';
import { useVaultFormContext } from '@modules/core/pages/Home/form-context';
import { FormInput, FormSelectInput } from '@modules/core/components/FormInput';
import { useState } from 'react';
import { formRowStyles } from '../../styles.css';

const BONUS_OPTIONS = [
  { value: INCOME_FREQUENCY.yearly },
  { value: INCOME_FREQUENCY.monthly }
];

const KYCEmployment = ({ formKey }: { formKey: string }) => {
  const { t } = useTranslation();
  const form = useVaultFormContext();
  const bonusWithLabel = BONUS_OPTIONS.map((bonus) => ({
    ...bonus,
    label: t(`kyc.employmentKyc.bonus.${bonus.value}`),
  }));
  const applicantIndex = Number(formKey.split('.')[1]);
  const initialValue = form
    .getValues()
    .applicants[
      applicantIndex
    ]?.incomes!.some((income) => income.type === INCOME_TYPE.bonus);
  const [hasBonus, setHasBonus] = useState(initialValue);

  const EMPLOYMENT_STATUS_OPTIONS = [
    {
      label: t('kyc.employmentKyc.status.other'),
      value: EMPLOYMENT_STATUS.other,
    },
    {
      label: t('kyc.employmentKyc.status.government'),
      value: EMPLOYMENT_STATUS.government,
    },
    {
      label: t('kyc.employmentKyc.status.selfEmployed'),
      value: EMPLOYMENT_STATUS.selfEmployed,
    },
    {
      label: t('kyc.employmentKyc.status.temporary'),
      value: EMPLOYMENT_STATUS.temporary,
    },
    {
      label: t('kyc.employmentKyc.status.unemployed'),
      value: EMPLOYMENT_STATUS.unemployed,
    }
  ];

  const addBonus = () => {
    const newBonus = {
      external_id: null,
      amount: null,
      type: INCOME_TYPE.bonus,
      frequency: INCOME_FREQUENCY.yearly,
    };

    form.insertListItem(`${formKey}.incomes`, newBonus);
  };

  const removeBonus = () => {
    form.removeListItem(`${formKey}.incomes`, 1);
  };

  const handleBonus = (isChecked: boolean) => {
    setHasBonus(isChecked);
    if (isChecked) {
      addBonus();
    } else {
      removeBonus();
    }
  };

  return (
    <Box bg='base.white' py='2' px='6' borderRadius='4'>
      <Flex className={ formRowStyles }>
        <FormSelectInput
          form={ form }
          name={ `${formKey}.employment_information.employment_type` }
          label={ t('common.forms.employmentStatus.label') }
          placeholder={ t('common.forms.employmentStatus.placeholder') }
          data={ EMPLOYMENT_STATUS_OPTIONS }
          data-test='select-employment-status'
        />
        <FormInput
          form={ form }
          name={ `${formKey}.employment_information.job_title` }
          label={ t('common.forms.jobTitle.label') }
          placeholder={ t('common.forms.jobTitle.placeholder') }
          type='text'
          data-test='input-job-title'
        />
      </Flex>
      <Flex className={ formRowStyles }>
        <FormInput
          form={ form }
          name={ `${formKey}.employment_information.start_date` }
          type='date'
          label={ t('common.forms.employmentStartDate.label') }
          placeholder={ t('common.forms.employmentStartDate.placeholder') }
          data-test='select-employment-start-date'
        />
        <FormInput
          form={ form }
          name={ `${formKey}.employment_information.no_of_annual_payments` }
          label={ t('common.forms.noOfAnnualPayments.label') }
          placeholder={ t('common.forms.noOfAnnualPayments.placeholder') }
          type='number'
          data-test='no-of-annual-payments'
        />
      </Flex>
      <Flex className={ formRowStyles }>
        <FormInput
          form={ form }
          name={ `${formKey}.incomes.0.amount` }
          label={ t('common.forms.monthlySalary.label') }
          placeholder={ t('common.forms.monthlySalary.placeholder') }
          type='number'
          trail={ CURRENCY.EUR }
          data-test='input-monthly-salary'
        />
        <Checkbox
          display='flex'
          styles={ { root: { alignItems: 'center' } } }
          label={ t('common.forms.receivesBonus.label') }
          checked={ hasBonus }
          onChange={ (e) => {
            handleBonus(e.target.checked);
          } }
          data-test='checkbox-has-a-bonus'
        />
      </Flex>
      {hasBonus && (
        <Flex className={ formRowStyles }>
          <FormSelectInput
            form={ form }
            name={ `${formKey}.incomes.1.frequency` }
            label={ t('common.forms.bonus.type.label') }
            placeholder={ t('common.forms.bonus.type.placeholder') }
            data={ bonusWithLabel }
            data-test='select-bonus-type'
          />
          <FormInput
            form={ form }
            name={ `${formKey}.incomes.1.amount` }
            label={ t('common.forms.bonus.value.label') }
            placeholder={ t('common.forms.bonus.value.placeholder') }
            type='number'
            trail={ CURRENCY.EUR }
            data-test='input-bonus-value'
          />
        </Flex>
      )}
    </Box>
  );
};

export default KYCEmployment;

import { c as j, t as x } from "./utils-BFJVqB2O.mjs";
import { z as s } from "zod";
import { t as c } from "i18next";
import "@mantine/notifications";
import "@tabler/icons-react";
import "react";
import "./vanilla-BVQNXG0s.mjs";
import "@mantine/vanilla-extract";
import "@mantine/core";
import "./Text-D75zcpkn.mjs";
import "clsx";
class L {
  constructor(e) {
    this.fetcher = e;
  }
  async get(e, r, t, a, l) {
    let p = e;
    if (r) {
      const d = new URLSearchParams();
      Object.keys(r).forEach((m) => {
        const o = r[m];
        Array.isArray(o) ? o.forEach((u) => {
          u != null && d.append(m, u);
        }) : o != null && d.append(m, o);
      }), p = `${e}?${d.toString()}`;
    }
    return this.fetcher.request(
      p,
      "GET",
      null,
      t,
      a,
      l
    );
  }
  async post(e, r, t, a, l) {
    return this.fetcher.request(e, "POST", r, t, a, l);
  }
  async update(e, r, t, a) {
    return this.fetcher.request(e, "PUT", r, t, a);
  }
  async delete(e, r, t, a) {
    return this.fetcher.request(e, "DELETE", null, t, a);
  }
  async patch(e, r, t, a) {
    return this.fetcher.request(e, "PATCH", r, t, a);
  }
}
class S {
}
const i = c("common.validation.requiredField"), f = c("common.validation.invalidValue"), g = c("common.validation.invalidEmail"), h = s.string({
  errorMap: (n, e) => e.data === null ? { message: i } : { message: e.defaultError }
}).min(1, { message: i }).max(100), E = s.string({ required_error: i }).email({ message: g }).min(1, { message: i }).max(100), v = s.number({
  errorMap: (n, e) => !e.data || e.data === "" ? { message: i } : { message: e.defaultError }
}).nonnegative(), F = s.coerce.number({
  errorMap: (n, e) => !e.data || e.data === "" ? { message: i } : { message: e.defaultError }
}).nonnegative(), V = s.date({ required_error: i }).min(new Date(1850, 1, 1)).max(/* @__PURE__ */ new Date()), q = (n) => s.string().refine((e) => n.includes(e), { message: f }), U = {
  textFieldValidator: h,
  emailFieldValidator: E,
  numberFieldValidator: v,
  numberCoerceFieldValidator: F,
  dateFieldValidator: V,
  enumFieldValidator: q
};
export {
  S as Fetcher,
  L as HttpClient,
  j as capturePdfFrameIntoHTML,
  x as toast,
  U as validators
};

import { useMutation } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { coreApi } from '@modules/core/api';

export const useRequestOtp = (opportunityId: string, isResend: boolean = false) => {
  const { mutateAsync, error, isPending } = useMutation({
    mutationKey: ['request-otp'],
    mutationFn: () =>
      coreApi.requestOtp(opportunityId, isResend),
    onError: (err) => {
      toast('error', { message: err?.message ?? 'Something went wrong' });
    },
  });

  return { error, mutateAsync, isPending };
};

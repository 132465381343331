import { DocumentCategory } from '@modules/documents/api/types';
import { BUSINESS_IDENTIFIER } from './const';

type VaultBusinessIdentifier = typeof BUSINESS_IDENTIFIER[keyof typeof BUSINESS_IDENTIFIER];

type DocumentsProgress = {
  total: number;
  valid: number;
  processing: number;
  percentage: number;
  uploaded: number;
  uploaded_percentage: number;
  invalid: number;
};

type KycProgress = {
  total: number;
  count: number;
  percentage: number;
  disabled: boolean;
};

export type ApplicantProgressDocs = DocumentsProgress &
{ progress_per_category: Record<DocumentCategory, DocumentsProgress> };

export type ApplicantProgressKyc = KycProgress & { progress_per_category:
Record<'personal_information' | 'residential_information' | 'employment_information', KycProgress> };

export type VaultKYCProgress = KycProgress & { progress_per_applicant: Record<string, ApplicantProgressKyc> };

export type VaultDocumentProgress = DocumentsProgress & { progress_per_applicant: Record<string, ApplicantProgressDocs> };

export type VaultOverview = {
  business_identifier: VaultBusinessIdentifier,
  progress: {
    kyc_progress: VaultKYCProgress,
    document_progress: VaultDocumentProgress
  }
};

export type DirectFeatureFlagResponse = {
  [flagKey: string]: {
    key: string;
  }
};

export const AMPLITUDE_FEATURE_FLAGS = {
  CLIENT_VAULT_MAINTENANCE_MODE: 'clientvault-maintenance-mode',
  CLIENT_HUB_VAULT_OTO_AUTH_FLOW: 'client-hub-vault-otp-auth-flow',
} as const;

export type PublicVaultData = {
  brokerage: { logo: string };
  main_applicant: { masked_email: string };
  broker: { auth_external_id: string };
};

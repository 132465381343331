/* eslint-disable max-len */
import {
  Box, Flex, Text
} from '@huspy/briks-web';
import { countriesJSON as jsonCountries } from '@huspy/forge/lib';
import { FormInput, FormSelectInput } from '@modules/core/components/FormInput';
import { useVaultFormContext } from '@modules/core/pages/Home/form-context';
import { Divider } from '@huspy/styled-system/jsx';
import { formRowStyles } from '../styles.css';

const STATES = jsonCountries
  .find((country: { alpha2: string }) => country.alpha2 === 'AE')
  .states.map((state: { name: string; stateCode: string }) => ({
    label: state.name,
    value: state.stateCode,
  }));

const KYCResidency = ({
  formKey,
  isResident,
}: {
  formKey: string;
  isResident: boolean;
}) => {
  const form = useVaultFormContext();

  return (
    <Box bg='base.white' py='2' px='6' borderRadius='4'>
      <Flex direction='column' gap='3'>
        {isResident && (
          <Box>
            <Text size='xl' my='4'>
              Residency details in UAE
            </Text>
            <Flex className={ formRowStyles }>
              <FormInput
                form={ form }
                name={ `${formKey}.residential_information.year_moved_to_country` }
                label='In which year did you move to UAE?'
                type='number'
                hasCommaDelimiter={ false }
                data-test='input-year-moved-to-uae'
              />
              <FormInput
                form={ form }
                label='Address'
                name={ `${formKey}.residential_information.address.street` }
                data-test='input-residency-address'
              />
            </Flex>
            <Flex className={ formRowStyles }>
              <FormInput
                form={ form }
                name={ `${formKey}.residential_information.address.city` }
                label='City'
                data-test='input-residency-city'
              />
              <FormSelectInput
                form={ form }
                name={ `${formKey}.residential_information.address.state_code` }
                label='State'
                data={ STATES }
                placeholder='Pick a state'
                data-test='select-state'
              />
            </Flex>
            <Flex className={ formRowStyles }>
              <FormInput
                form={ form }
                name={ `${formKey}.residential_information.po_box` }
                label='PO Box'
                data-test='input-po-box'
              />
              <FormInput
                form={ form }
                type='number'
                label='How long are you living in your current address?'
                name={ `${formKey}.residential_information.no_of_months_lived_in_current_residence` }
                trail='years'
                data-test='input-current-address-years-lived'
              />
            </Flex>
            <FormSelectInput
              form={ form }
              name={ `${formKey}.residential_information.status_of_current_residence` }
              label='Status of current residence'
              placeholder='Select your current residence'
              data={ [
                { value: 'owned', label: 'Owned' },
                { value: 'rented', label: 'Rented' }
              ] }
              data-test='select-status-of-current-residence'
            />
            <Divider my='10' />
            <Text size='xl' my='4'>
              References
            </Text>
            <Flex className={ formRowStyles }>
              <FormInput
                form={ form }
                name={ `${formKey}.residential_information.reference.name_of_first_friend_or_relative_in_the_purchasing_country` }
                label='Name of friend/relative (1)'
                data-test='input-name-of-friend-1'
              />
              <FormInput
                form={ form }
                type='phone'
                name={ `${formKey}.residential_information.reference.mobile_no_of_first_friend_or_relative_in_the_purchasing_country` }
                label='Mobile number of friend/relative (1)'
                data-test='input-mobile-of-friend-1'
              />
            </Flex>
            <Flex className={ formRowStyles }>
              <FormInput
                form={ form }
                name={ `${formKey}.residential_information.reference.name_of_second_friend_or_relative_in_the_purchasing_country` }
                label='Name of friend/relative (2)'
                data-test='input-name-of-friend-2'
              />
              <FormInput
                form={ form }
                type='phone'
                name={ `${formKey}.residential_information.reference.mobile_no_of_sec_friend_or_relative_in_the_purchasing_country` }
                label='Mobile number of friend/relative (2)'
                data-test='input-mobile-of-friend-2'
              />
            </Flex>
          </Box>
        )}
        <Divider my='10' />
        <Box>
          <Text size='xl' my='4'>
            Home country residency details
          </Text>
          <Flex className={ formRowStyles }>
            <FormInput
              form={ form }
              label='Address'
              name={ `${formKey}.residential_information.address_in_home_country.street` }
              data-test='input-home-address'
            />
            <FormInput
              form={ form }
              label='City'
              name={ `${formKey}.residential_information.address_in_home_country.city` }
              data-test='input-home-city'
            />
          </Flex>
          <Flex className={ formRowStyles }>
            <FormSelectInput
              form={ form }
              label='Country'
              name={ `${formKey}.residential_information.address_in_home_country.country_code` }
              data={ jsonCountries.map(
                (country: { name: string; alpha2: string }) => ({
                  label: country.name,
                  value: country.alpha2,
                })
              ) }
              placeholder='Pick a country'
            />
            <FormInput
              form={ form }
              label='Postal / Zip'
              name={ `${formKey}.residential_information.address_in_home_country.postal_code` }
              data-test='input-home-zip'
            />
          </Flex>
          <FormInput
            form={ form }
            label='Phone number'
            type='phone'
            name={ `${formKey}.residential_information.mobile_no_in_home_country` }
            data-test='input-home-mobile'
          />
          <Divider my='10' />
          <Text size='xl' my='4'>
            References
          </Text>
          <Flex className={ formRowStyles }>
            <FormInput
              form={ form }
              label='Name of friend/relative (1)'
              name={ `${formKey}.residential_information.reference.name_of_first_friend_or_relative_in_the_home_country` }
              data-test='input-home-name-of-friend-1'
            />
            <FormInput
              form={ form }
              label='Mobile number of friend/relative (1)'
              type='phone'
              name={ `${formKey}.residential_information.reference.mobile_no_of_first_friend_or_relative_in_home_country` }
              data-test='input-home-mobile-of-friend-1'
            />
          </Flex>
          <Flex className={ formRowStyles }>
            <FormInput
              form={ form }
              label='Name of friend/relative (2)'
              name={ `${formKey}.residential_information.reference.name_of_second_friend_or_relative_in_home_country` }
              data-test='input-home-name-of-friend-2'
            />
            <FormInput
              form={ form }
              type='phone'
              label='Mobile number of friend/relative (2)'
              name={ `${formKey}.residential_information.reference.mobile_no_of_second_friend_or_relative_in_home_country` }
              data-test='input-home-mobile-of-friend-2'
            />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default KYCResidency;

import { useEffect } from 'react';
import { useAuthStore } from '../store';

const usePersistAuth = () => {
  const { setToken, getTokenFromSessionStorage } = useAuthStore();
  const tokenFromSessionStorage = getTokenFromSessionStorage();

  useEffect(() => {
    if (tokenFromSessionStorage) {
      setToken(tokenFromSessionStorage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { tokenFromSessionStorage };
};

export default usePersistAuth;

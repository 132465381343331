import { z } from 'zod';

import personalSchema from './Personal/schema';
import residencySchema from './Residency/schema';
import { getEmploymentSchema, getIncomesSchema } from './Employment/schema';
import { creditCardSchema, getLiabilitySchema } from './Liabilities/schema';

const getSchema = (isSpainBusiness: boolean) =>
  z.object({
    applicants: z.array(z.object({
      applicantId: z.string(),
      personal_information: personalSchema,
      residential_information: residencySchema,
      incomes: getIncomesSchema(isSpainBusiness),
      employment_information: getEmploymentSchema(isSpainBusiness),
      creditCards: creditCardSchema,
      personalLoan: getLiabilitySchema(isSpainBusiness),
      autoLoan: getLiabilitySchema(isSpainBusiness),
      mortgage: getLiabilitySchema(isSpainBusiness),
    })),
  });

const customZodResolver = (zodSchema: z.Schema) => (values: unknown) => {
  const result = zodSchema.safeParse(values);

  if (!result.success) {
    const errors: Record<string, string[]> = {};

    result.error.errors.forEach((err) => {
      const path = err.path.join('.');
      if (!errors[path]) {
        errors[path] = [];
      }
      errors[path].push(err.message);
    });

    return errors;
  }

  return {};
};

export const schemaResolver = (isSpainBusiness: boolean) =>
  customZodResolver(getSchema(isSpainBusiness));

import { INCOME_TYPE } from '@modules/kyc/api/const';
import {
  enumSchema, numberSchema, stringSchema
} from '@shared/schema';
import { z } from 'zod';
import { t } from 'i18next';
import { requiredFieldError } from '@shared/validators';
import { employmentSchemaAe } from './ae/schema';
import { employmentSchemaEs } from './es/schema';

const IncomeTypeSchema = enumSchema([
  INCOME_TYPE.allowance,
  INCOME_TYPE.salary,
  INCOME_TYPE.wage,
  INCOME_TYPE.bonus,
  INCOME_TYPE.commision,
  INCOME_TYPE.dividends,
  INCOME_TYPE.rental,
  INCOME_TYPE.other
]);

const incomeSchema = (isSpainBusiness: boolean) =>
  z
    .object({
      external_id: stringSchema,
      type: IncomeTypeSchema,
      amount: numberSchema,
      frequency: stringSchema,
    })
    .superRefine((data, ctx) => {
      const isTypeFilled = data.type !== null && data.type !== undefined;
      const isAmountFilled = data.amount !== undefined && data.amount !== null;
      // If only one of the fields is filled, add errors to the missing field
      if (isTypeFilled && !isAmountFilled) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['amount'],
          message: isSpainBusiness
            ? t(requiredFieldError)
            : "'Monthly income' is required when 'Income type' is provided.",
        });
      } else if (!isTypeFilled && isAmountFilled) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['type'],
          message:
            "'Income type' is required when 'Monthly income' is provided.",
        });
      }
    });

export const getIncomesSchema = (isSpainBusiness: boolean) =>
  z.array(incomeSchema(isSpainBusiness));

export const getEmploymentSchema = (isSpainBusiness: boolean) =>
  (isSpainBusiness ? employmentSchemaEs : employmentSchemaAe);

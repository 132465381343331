import { useAuthStore } from '@modules/core/store';
import {
  Navigate, Outlet, useLocation,
} from 'react-router-dom';
import usePersistAuth from '@modules/core/hooks/usePersistAuth';
import { ErrorBoundary } from '@shared/errorBoundary';
import { useEffect } from 'react';
import { useHandleLanguage } from '@modules/core/hooks/useHandleLanguage';
import { Box } from '@huspy/briks-web';
import useGetOpportunityId from '@modules/core/hooks/useGetOpportunityId';

const Layout = () => {
  const { tokenFromSessionStorage } = usePersistAuth();
  const { token } = useAuthStore();
  const oppId = useGetOpportunityId();

  const location = useLocation();
  useHandleLanguage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (!tokenFromSessionStorage && !token && oppId) {
    return <Navigate to={ `/login/${oppId}` } />;
  }

  return (
    <Box>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </Box>
  );
};

export default Layout;

/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import Select, { SelectItem } from '@modules/core/components/Select';
import {
  Box,
  Flex,
  Input,
  InputDescription,
  InputLabel,
  PhoneNumberInput,
  Text,
} from '@huspy/briks-web';
import { Divider } from '@huspy/styled-system/jsx';
import { UseFormReturnType } from '@mantine/form';
import briksTheme from '@shared/briksTheme';
import { formStyles } from './styles.css';

export const SectionHeader = ({
  title,
  Icon,
}: {
  title: string;
  Icon: (props: React.SVGProps<SVGSVGElement>) => React.JSX.Element;
}) => (
  <Flex justify='center' align='center' my='4'>
    <Flex align='center' justify='center'>
      <Flex align='center' justify='center' h='100%' w='100%' mr='2'>
        <Icon color={ briksTheme.colors['neutral.600'] } />
      </Flex>
      <Text size='md' mr='4' color='neutral.600' whiteSpace='nowrap'>
        {title}
      </Text>
    </Flex>
    <Divider />
  </Flex>
);

type CaseInputProps<T> = {
  form: UseFormReturnType<T, (values: T) => T>;
  name: string;
  placeholder?: string;
  label?: string;
  type?: 'text' | 'number' | 'date' | 'phone';
  trail?: string;
  disabled?: boolean;
  hasCommaDelimiter?: boolean;
};

const _FormInput = <T, _>({
  form,
  name,
  label,
  placeholder,
  type = 'text',
  trail,
  disabled = false,
  hasCommaDelimiter = true,
  ...rest
}: CaseInputProps<T>) => {
  const { error, onChange, defaultValue } = form.getInputProps(name);
  const [value, setValue] = useState(defaultValue);

  const isError = !!error;
  const errorText = error ?? '';

  // Helper function to render input label and description
  const renderInputLabel = () =>
    label && <InputLabel isError={ isError }>{label}</InputLabel>;
  const renderInputDescription = () => (
    <InputDescription mt='1' isError={ isError }>
      {errorText}
    </InputDescription>
  );

  const formatValue = (_hasCommaDelimiter: boolean) => {
    if (value === undefined || value === '' || value === null) return '';
    if (value === 0) return 0;
    if (!_hasCommaDelimiter) return Number(value);
    return Number(value).toLocaleString('en-US');
  };

  // Shared props for Input components
  const sharedInputProps = {
    id: name,
    name,
    disabled,
    placeholder: placeholder ?? label,
    isError,
    ...rest,
  };

  // Input type-specific handlers/components
  const inputComponents: Record<string, JSX.Element> = {
    phone: (
      <PhoneNumberInput
        { ...sharedInputProps }
        defaultCountry='ae'
        onChange={ (e) => {
          if (e.inputValue.length > 0) {
            form.clearFieldError(name);
          }
          if (e.inputValue.length === 0) {
            onChange('');
            setValue('+971');
          } else {
            onChange(e.phone);
            setValue(e.phone);
          }
        } }
        value={ value || '' }
      />
    ),
    number: (
      <Input
        { ...sharedInputProps }
        type='text'
        value={ formatValue(hasCommaDelimiter) }
        onChange={ (e) => {
          const { value: stringValue } = e.target;
          const cleanedStringValue = stringValue.replaceAll(/\D/g, '');
          if (cleanedStringValue === '') {
            onChange();
            setValue('');
          } else {
            onChange(Number(cleanedStringValue));
            setValue(Number(cleanedStringValue));
          }
        } }
        onWheel={ (e) => (e.target as HTMLInputElement).blur() }
        icon={ trail && (
          <Text size='md' color='neutral.400'>
            {trail}
          </Text>
        ) }
      />
    ),
    default: (
      <Input
        { ...sharedInputProps }
        type={ type }
        defaultValue={ defaultValue }
        onChange={ (e) => onChange(e.target.value) }
        icon={ trail && (
          <Text size='md' color='neutral.400'>
            {trail}
          </Text>
        ) }
      />
    ),
  };

  // Pick the appropriate input component based on type
  const selectedInput = inputComponents[type] || inputComponents.default;

  return (
    <Box className={ formStyles }>
      {renderInputLabel()}
      {selectedInput}
      {renderInputDescription()}
    </Box>
  );
};

type CaseSelectInputProps<T> = CaseInputProps<T> & {
  data: SelectItem[];
};

const _FormSelectInput = <T, _>({
  form,
  name,
  label,
  data,
  ...rest
}: CaseSelectInputProps<T>) => {
  const { defaultValue } = form.getInputProps(name);

  return (
    <Box className={ formStyles } id={ name }>
      <Select
        name={ name }
        label={ label }
        data={ data }
        placeholder={ label }
        onValueChange={ (e) => {
          form.getInputProps(name).onChange(e.value[0]);
        } }
        isError={ !!form.getInputProps(name)?.error }
        defaultValue={ [defaultValue] }
        { ...rest }
      />
      <InputDescription color='error.800' mt='1'>
        {form.getInputProps(name)?.error ?? ''}
      </InputDescription>
    </Box>
  );
};

export const FormInput = React.memo(
  _FormInput,
  (prevProps, nextProps) =>
    prevProps.form.getInputProps(prevProps.name).error
      === nextProps.form.getInputProps(nextProps.name).error
    && prevProps.form.getInputProps(prevProps.name).defaultValue
      === nextProps.form.getInputProps(nextProps.name).defaultValue
) as typeof _FormInput;

export const FormSelectInput = React.memo(
  _FormSelectInput,
  (prevProps, nextProps) =>
    prevProps.form.getInputProps(prevProps.name).error
      === nextProps.form.getInputProps(nextProps.name).error
    && prevProps.form.getInputProps(prevProps.name).defaultValue
      === nextProps.form.getInputProps(nextProps.name).defaultValue
) as typeof _FormSelectInput;

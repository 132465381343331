import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { coreApi } from '@modules/core/api';
import { useAuthStore } from '@modules/core/store/index';

const useAccessTheVault = () => {
  const { saveTokenToSessionStorage } = useAuthStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { error, mutate, isPending } = useMutation({
    mutationKey: ['login'],
    mutationFn: ({ password, opportunityId }: { opportunityId: string, password: string }) =>
      coreApi.login(password, opportunityId),
    onSuccess: ({ access_token }, { opportunityId }) => {
      if (access_token) {
        saveTokenToSessionStorage(access_token);
        toast('info', { message: t('login.notifications.loginSuccessful') });
        trackAmplitudeEvent('ClientVaultLogin', { opportunityId });
        navigate(`/${opportunityId}`);
      }
    },
    onError: (err) => {
      toast('error', { message: err?.message ?? 'Something went wrong' });
    },
  });

  return { error, mutate, isPending };
};

export default useAccessTheVault;

/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import { useAuthStore, useBrokerageStore } from '@modules/core/store';
import useKYCData from '@modules/kyc/hooks/queries/useKYCData';
import {
  KnowYourCustomer,
  FormOperationsProvider,
  initialFormValuesMapper,
  kycSchemaResolver,
} from '@huspy/forge/features';
import {
  Applicant as ApplicantKYC,
  EmploymentKYCData,
  KYCCountry,
  KYCData,
} from '@modules/kyc/api/types';
import {
  useVaultFormContext,
  useVaultsForm,
  VaultFormProvider,
} from '@modules/core/pages/Home/form-context';
import React, {
  useEffect, useMemo, useState
} from 'react';
import useVaultData from '@modules/documents/hooks/queries/useVaultData';
import { Applicant as ApplicantDocuments } from '@modules/documents/api/types';
import { BulkUploadWrapper } from '@modules/documents/components/BulkUpload';
import { Box } from '@huspy/briks-web';
import useVaultProgress from '@modules/core/hooks/queries/useVaultProgress';
import { BUSINESS_IDENTIFIER } from '@modules/core/api/const';
import { Skeleton } from '@mantine/core';
import useUpdateKYCData from '@modules/kyc/hooks/mutations/updateKYCDataV2';
import { scrollToError } from '@shared/utils';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';
import { useClientFormErrorHandler } from '@modules/kyc/hooks/useHandleError';
import {
  resetAmplitudeIdentity,
  trackAmplitudeEvent,
} from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import useGetOpportunityId from '@modules/core/hooks/useGetOpportunityId';
import { useNavigate } from 'react-router-dom';
import useGetBanks from '@modules/kyc/hooks/queries/useGetBanks';
import { VaultAppShell } from './VaultAppShell';
import { VAULT_TAB, VaultTab } from './types';
import { Header } from './Header';

function convertToApplicantType(data: KYCData): ApplicantKYC[] {
  return Object.entries(data).map(([applicantId, applicantData]) => ({
    applicantId,
    ...applicantData,
  }));
}

const FormsTab = React.memo(
  ({
    applicantsKYC,
    isSpainBusiness,
  }: {
    applicantsKYC: ApplicantKYC<KYCCountry>[];
    isSpainBusiness: boolean;
  }) => (
    <>
      {applicantsKYC.map((applicant, idx) => (
        <Box key={ applicant.applicantId } my='8'>
          <Header
            firstName={ applicant.personal_information.first_name }
            lastName={ applicant.personal_information.last_name }
            type={ applicant.applicant_type }
          />
          <KnowYourCustomer
            key={ applicant.applicantId }
            formKey={ `applicants.${idx}` }
            applicant={ applicant }
            isSpainBusiness={ isSpainBusiness }
          />
        </Box>
      ))}
    </>
  )
);

const DocumentsTab = React.memo(
  ({
    applicantsDocuments,
    opportunityId,
  }: {
    applicantsDocuments: ApplicantDocuments[];
    opportunityId: string;
  }) => (
    <>
      {applicantsDocuments.map((applicant) => (
        <Box key={ applicant.applicant_id } my='8'>
          <Header
            firstName={ applicant.first_name }
            lastName={ applicant.last_name }
            type={ applicant.applicant_type }
          />
          <BulkUploadWrapper
            key={ applicant.applicant_id }
            oppId={ opportunityId }
            applicantId={ applicant.applicant_id }
            documents={ applicant.required_documents }
            isSimplified
          />
        </Box>
      ))}
    </>
  )
);

const Form = ({
  opportunityId,
  applicantsKYC,
  applicantsDocuments,
  isSpainBusiness,
  formDescription,
  isFormCompleted,
}: {
  opportunityId: string;
  applicantsKYC: ApplicantKYC<KYCCountry>[];
  applicantsDocuments: ApplicantDocuments[];
  formDescription: string;
  isFormCompleted: boolean;
  isSpainBusiness: boolean;
}) => {
  const { t } = useTranslation();
  const { data: brokerage } = useBrokerageStore();
  const {
    employmentAE,
    employmentES,
    personal,
    residency,
    liabilities,
    incomeAE,
    incomeEs,
  } = initialFormValuesMapper;

  const initialValues = useMemo(
    () => ({
      applicants: applicantsKYC.map((applicant) => ({
        applicantId: applicant.applicantId,
        personal_information: personal.getInitialValues(
          applicant.personal_information
        ),
        residential_information: residency.getInitialValues(
          applicant.residential_information
        ),
        incomes: isSpainBusiness
          ? incomeEs.getInitialValues(applicant.financial_information.incomes)
          : incomeAE.getInitialValues(applicant.financial_information.incomes),
        employment_information: isSpainBusiness
          ? employmentES.getInitialValues(
            applicant.employment_information as EmploymentKYCData<'ES'>
          )
          : employmentAE.getInitialValues(
            applicant.employment_information as EmploymentKYCData<'AE'>
          ),
        ...liabilities.getInitialValues(
          applicant.financial_information.liabilities
        ),
      })),
    }),
    [JSON.stringify(applicantsKYC)]
  );

  const form = useVaultsForm({
    mode: 'uncontrolled',
    initialValues,
    validate: kycSchemaResolver(isSpainBusiness),
  });

  const memoizedForm = useMemo(() => form, [JSON.stringify(form.errors)]);

  const [activeVaultTab, setActiveVaultTab] = useState<VaultTab>(
    VAULT_TAB.FORMS
  );
  const isFormsTabActive = activeVaultTab === VAULT_TAB.FORMS;
  const isDocumentsTabActive = activeVaultTab === VAULT_TAB.DOCUMENTS;
  const { mutateAsync, isPending, error } = useUpdateKYCData<KYCCountry>(opportunityId);
  const { data: banksData } = useGetBanks();

  const banks = useMemo(
    () =>
      banksData?.map((bank) => ({
        label: bank.title,
        value: bank.title,
      })),
    [banksData]
  );

  const handleSubmit = async () => {
    const { errors, hasErrors } = form.validate();

    if (hasErrors) {
      trackAmplitudeEvent(
        USER_EVENTS.FORM_FIELDS.VAULT_FIELDS_VALIDATION_RAISED,
        {
          brokerage_external_id: brokerage?.external_id,
          brokerage_name: brokerage?.name,
          case_id: opportunityId,
          error: errors,
        }
      );
      scrollToError(errors);
      return;
    }

    if (!form.isDirty()) {
      return;
    }
    const data = form.getValues();
    try {
      await mutateAsync(data);
      form.resetDirty();
    } catch (error_) {
      console.error(error_);
    }
  };

  const showSavedProgressToast = () => {
    if (!form.isDirty()) {
      toast('neutral', { message: t('home.progressSave') });
    }
  };

  useClientFormErrorHandler({ clientErrors: error, form });

  useEffect(() => {
    form.setValues(initialValues);
  }, [JSON.stringify(applicantsKYC)]);

  return (
    <VaultFormProvider form={ memoizedForm }>
      <FormOperationsProvider operations={ { useVaultFormContext, banks } }>
        <form
          onSubmit={ async (e) => {
            e.preventDefault();
            await handleSubmit();
          } }
        >
          <VaultAppShell
            activeVaultTab={ activeVaultTab }
            setActiveVaultTab={ setActiveVaultTab }
            isLoading={ isPending }
            showSavedProgressToast={ showSavedProgressToast }
            formDescription={ formDescription }
            isFormCompleted={ isFormCompleted }
          >
            <Box display={ isFormsTabActive ? 'block' : 'none' } w='100%'>
              <FormsTab
                applicantsKYC={ applicantsKYC }
                isSpainBusiness={ isSpainBusiness }
              />
            </Box>
            <Box display={ isDocumentsTabActive ? 'block' : 'none' } w='100%'>
              <DocumentsTab
                applicantsDocuments={ applicantsDocuments }
                opportunityId={ opportunityId }
              />
            </Box>
          </VaultAppShell>
        </form>
      </FormOperationsProvider>
    </VaultFormProvider>
  );
};

const Home = () => {
  const oppId = useGetOpportunityId();

  const {
    data,
    isLoading: isKYCDataLoading,
  } = useKYCData<KYCCountry>(oppId);
  const {
    data: documentData,
    isLoading: isDocumentDataLoading,
  } = useVaultData(oppId);
  const {
    data: vaultProgressData,
    isLoading: isVaultProgressLoading,
    error,
  } = useVaultProgress(oppId, false);
  const { t } = useTranslation();

  const { logOut } = useAuthStore();
  const navigate = useNavigate();

  if (error?.statusCode === 403) {
    logOut();
    resetAmplitudeIdentity();
    navigate('/');
  }

  if (isKYCDataLoading || isDocumentDataLoading || isVaultProgressLoading) {
    return (
      <VaultAppShell
        activeVaultTab={ VAULT_TAB.FORMS }
        setActiveVaultTab={ () => {} }
        isLoading={ false }
        formDescription=''
        isFormCompleted={ false }
      >
        <Box my='8' w='100%'>
          <Skeleton visible h='calc(100vh - 180px)' radius='lg' />
        </Box>
      </VaultAppShell>
    );
  }

  const applicantsKYC = convertToApplicantType(data.kyc);
  const applicantsDocuments = documentData.applicants;
  const isSpainBusiness = vaultProgressData.businessIdentifier === BUSINESS_IDENTIFIER.ES_BAYTECA;
  const { count, total, percentage } = vaultProgressData.kycProgress;

  const formDescription = t('home.mandatoryFields', { count, total });

  return (
    <Form
      opportunityId={ oppId! }
      applicantsKYC={ applicantsKYC }
      applicantsDocuments={ applicantsDocuments }
      isSpainBusiness={ isSpainBusiness }
      formDescription={ formDescription }
      isFormCompleted={ percentage === 100 }
    />
  );
};

export default Home;

import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import useGetFeatureFlagsDirectly from '@modules/core/hooks/queries/useGetFeatureFlagsDirectly';
import { usePublicVaultData } from '@modules/core/hooks/queries/usePublicVaultData';
import useGetOpportunityId from '@modules/core/hooks/useGetOpportunityId';
import { Navigate } from 'react-router-dom';
import { useAuthStore } from '@modules/core/store';
import usePersistAuth from '@modules/core/hooks/usePersistAuth';
import { useHandleLanguage } from '@modules/core/hooks/useHandleLanguage';
import { useEffect } from 'react';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { PassAuthFlow } from './PassFlow';
import { OtpAuthFlow } from './OtpFlow';

export const Authentication = () => {
  const oppId = useGetOpportunityId();
  const {
    data: publicData,
    error: publicDataError,
    isLoading: isPublicDataLoading,
  } = usePublicVaultData(oppId);
  const { token } = useAuthStore();
  const { tokenFromSessionStorage } = usePersistAuth();
  const brokerExternalId = publicData?.broker.auth_external_id;
  const {
    data: isFeatureFlagOn,
    isLoading: isFeatureFlagLoading,
    isFetched,
  } = useGetFeatureFlagsDirectly(
    AMPLITUDE_FEATURE_FLAGS.CLIENT_HUB_VAULT_OTO_AUTH_FLOW,
    brokerExternalId
  );

  const isLoading = isPublicDataLoading || isFeatureFlagLoading;

  useHandleLanguage();

  useEffect(() => {
    if (oppId && !token) {
      trackAmplitudeEvent('ClientVaultAccess', { opportunity_id: oppId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oppId]);

  if (tokenFromSessionStorage && token && oppId) {
    return <Navigate to={ `/${oppId}` } />;
  }

  if (isLoading) {
    return <></>;
  }

  if (publicDataError?.statusCode === 404) {
    return <Navigate to='/' />;
  }

  if (publicDataError || !brokerExternalId) {
    return <PassAuthFlow />;
  }

  if (!isFetched) {
    return <></>;
  }

  if (isFeatureFlagOn) {
    return (
      <OtpAuthFlow
        maskedEmail={ publicData.main_applicant.masked_email }
        logo={ publicData.brokerage.logo }
      />
    );
  }

  return <PassAuthFlow />;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
/* eslint-disable no-restricted-syntax */
export const retrieveFormErrors = (
  errors: Record<string, any>,
  recursiveKey?: string
) => {
  let errorsToSet: Record<string, string> = {};
  if (errors) {
    const apiErrors = Object.entries(errors);
    if (apiErrors.length > 0) {
      apiErrors.forEach(([key, value]) => {
        if (Array.isArray(value)) {
          const errorKey = recursiveKey ? `${recursiveKey}.${key}` : key;
          // eslint-disable-next-line prefer-destructuring
          errorsToSet[errorKey] = value[0];
        } else if (typeof value === 'object') {
          const res = retrieveFormErrors(
            value,
            recursiveKey ? `${recursiveKey}.${key}` : key
          );
          errorsToSet = { ...errorsToSet, ...res };
        }
      });
    }
  }
  return errorsToSet;
};

/**
 * Removes null, undefined, and empty string values from an object
 * @param obj The object to clean
 * @returns A new object with only defined, non-empty values
 */
export const cleanObject = <T extends object>(obj: T): Partial<T> =>
  Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) =>
        value !== null
        && value !== undefined
        && (typeof value !== 'string' || value.trim() !== '')
    )
  ) as Partial<T>;

export const isValidNumberValue = (value: number | undefined | null) =>
  value !== null && value !== undefined;

export const flattenValidationErrors = (
  errors: Record<string, any>,
  parentKey = ''
): Record<string, string> => {
  const flattenedErrors: Record<string, string> = {};

  for (const [key, value] of Object.entries(errors)) {
    // Construct the full key path
    const fullKey = parentKey ? `${parentKey}.${key}` : key;

    // If the value is an array of strings (error messages), take the first message
    if (
      Array.isArray(value)
      && value.length > 0
      && typeof value[0] === 'string'
    ) {
      // eslint-disable-next-line prefer-destructuring
      flattenedErrors[fullKey] = value[0];
    }
    // If the value is an object, recursively flatten
    if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
      const nestedErrors = flattenValidationErrors(value, fullKey);
      Object.assign(flattenedErrors, nestedErrors);
    }
  }

  return flattenedErrors;
};

export const flattenRequestErrors = (
  responses: PromiseSettledResult<any>[]
): Record<string, string> => {
  const requestErrors: Record<string, string> = {};

  for (const [index, response] of responses.entries()) {
    if (response.status === 'rejected') {
      const errors = flattenValidationErrors(
        response.reason.data,
        `applicants.${index}`
      );

      // Direct assignment is faster than spread operator
      for (const [key, value] of Object.entries(errors)) {
        requestErrors[key] = value;
      }
    }
  }

  return requestErrors;
};

import { coreApi } from '@modules/core/api';
import { useQuery } from '@tanstack/react-query';

export const usePublicVaultData = (opportunityId: string | undefined) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['publicVaultData', opportunityId],
    queryFn: () => coreApi.getPublicVaultData(opportunityId!),
    enabled: !!opportunityId,
    retry: false,
  });

  return {
    data,
    isLoading,
    error,
  };
};

import {
  Flex, Text, Title
} from '@huspy/briks-web';
import { useTranslation } from 'react-i18next';
import {
  responsiveContainerStyles,
  leftResponsiveSectionStyles,
  rightResponsiveSectionStyles
} from './styles.css';

const DescriptionText = ({ text }: { text: string }) => (
  <Text size='2xl' textAlign='center' fontWeight='medium'>
    {text}
  </Text>
);

export const Landing = () => {
  const { t } = useTranslation();

  return (
    <Flex
      className={ responsiveContainerStyles }
      justify='center'
      align='center'
      h='100vh'
      gap='2'
    >
      <Flex
        className={ leftResponsiveSectionStyles }
        justify='center'
        align='center'
        h='100%'
      >
        <Title as='h3' size='h3' fontWeight='medium'>
          Client Vault
        </Title>
      </Flex>
      <Flex className={ rightResponsiveSectionStyles } p='4'>
        <Flex
          border='1px solid'
          borderColor='neutral.100'
          borderRadius='2'
          py='16'
          px='8'
          gap='4'
          direction='column'
        >
          <DescriptionText text={ t('home.landing.welcomeMessage') } />
          <DescriptionText text={ t('home.landing.accessInstructions') } />
        </Flex>
      </Flex>
    </Flex>
  );
};

/* eslint-disable max-len */
import { z } from 'zod';
import {
  enumSchema, numberSchema, stringSchema
} from '@shared/schema';
import { LIABILITY_TYPE } from '@modules/kyc/api/const';
import { requiredFieldError } from '@shared/validators';
import { t } from 'i18next';

const LiabilityTypeSchema = enumSchema([
  LIABILITY_TYPE.creditCard,
  LIABILITY_TYPE.personalLoan,
  LIABILITY_TYPE.autoLoan,
  LIABILITY_TYPE.mortgage
]);

// Check if any of the fields is filled (not null or undefined)
const isFilled = (field: unknown) => field !== null && field !== undefined;

export const getLiabilitySchema = (isSpainBusiness: boolean) =>
  z
    .object({
      type: LiabilityTypeSchema,
      value: numberSchema,
      description: stringSchema,
      outstanding_amount: numberSchema,
      monthly_cost: numberSchema,
      external_id: stringSchema,
    })
    .superRefine((data, ctx) => {
      const {
        value, outstanding_amount, monthly_cost, description,
      } = data;
      const anyFieldFilled = isSpainBusiness
        ? [outstanding_amount, monthly_cost].some((element) =>
          isFilled(element))
        : [value, outstanding_amount, monthly_cost, description].some((element) =>
          isFilled(element));

      if (anyFieldFilled) {
        const errors = [
          {
            condition: !isFilled(value) && !isSpainBusiness,
            path: 'value',
            message:
              "'Total amount' is required when any of 'Outstanding amount' or 'Monthly installment' is filled.",
          },
          {
            condition: !isFilled(description) && !isSpainBusiness,
            path: 'description',
            message:
              'Bank is required field',
          },
          {
            condition: !isFilled(outstanding_amount),
            path: 'outstanding_amount',
            message: isSpainBusiness
              ? t(requiredFieldError)
              : "'Outstanding amount' is required when any of 'Total amount' or 'Monthly installment' is filled.",
          },
          {
            condition: !isFilled(monthly_cost),
            path: 'monthly_cost',
            message: isSpainBusiness
              ? t(requiredFieldError)
              : "'Monthly installment' is required when any of 'Total amount' or 'Outstanding amount' is filled.",
          }
        ];

        errors.forEach(({ condition, path, message }) => {
          if (condition) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: [path],
              message,
            });
          }
        });
      }
    });

export const creditCardSchema = z.array(
  z
    .object({
      type: LiabilityTypeSchema,
      value: numberSchema,
      description: stringSchema,
      outstanding_amount: numberSchema,
      monthly_cost: numberSchema,
      external_id: stringSchema,
    })
    .superRefine((data, ctx) => {
      const { value, description } = data;

      const anyFieldFilled = isFilled(value) || isFilled(description);

      if (anyFieldFilled) {
        if (!isFilled(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['value'],
            message: "'Limit' is required when 'Bank' is filled.",
          });
        }
        if (!isFilled(description)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['description'],
            message: "'Bank' is required when 'Limit' is filled.",
          });
        }
      }
    })
);

import { lazy } from 'react';
import Layout from '@shared/layout';
import { Landing } from '@modules/core/pages/Landing';
import { PassAuthFlow } from '@modules/core/pages/Authentication/PassFlow';
import { Authentication } from '@modules/core/pages/Authentication';
import Home from '@modules/core/pages/Home/index';
import { Navigate, createBrowserRouter } from 'react-router-dom';

const ProposalPreviewPage = lazy(() => import('@modules/proposals/pages/Preview/index'));

export const router: ReturnType<typeof createBrowserRouter> = createBrowserRouter([
  {
    path: '/login/v1/:oppId?',
    element: (
      <PassAuthFlow />
    ),
  },
  {
    path: '/login/:oppId?',
    element: (
      <Authentication />
    ),
  },
  {
    path: '/proposal/:proposalId',
    element: (
      <ProposalPreviewPage />
    ),
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Landing />,
      },
      {
        path: '/:oppId',
        element: <Home />,
      }
    ],
  },
  {
    path: '*',
    element: <Navigate to='/' />,
  }
]);

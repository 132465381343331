/* eslint-disable no-restricted-syntax */
import { UseFormReturnType } from '@mantine/form';
import { scrollToError } from '@shared/utils';
import { useEffect } from 'react';

export const useClientFormErrorHandler = <T, _>({
  clientErrors,
  form,
}: {
  clientErrors: Record<string, any>;
  form: UseFormReturnType<T, (values: T) => T>;
}) => {
  const handleFieldError = (field: string, message: string) => {
    form.setFieldError(field, message);
    scrollToError({ [field]: message });
  };

  useEffect(() => {
    if (clientErrors && Object.keys(clientErrors).length > 0) {
      for (const [key, value] of Object.entries(clientErrors)) {
        handleFieldError(key, value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(clientErrors)]);
};

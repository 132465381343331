import { AMPLITUDE_FEATURE_FLAGS } from '@modules/core/api/types';
import useGetFeatureFlagsDirectly from '@modules/core/hooks/queries/useGetFeatureFlagsDirectly';
import { ReactNode } from 'react';

const BehindMaintenance = ({ children }: { children: ReactNode }) => {
  const featureFlag = useGetFeatureFlagsDirectly(
    AMPLITUDE_FEATURE_FLAGS.CLIENT_VAULT_MAINTENANCE_MODE
  );

  if (featureFlag.data) {
    window.location.replace('/maintenance');
    return;
  }

  return children;
};

export default BehindMaintenance;

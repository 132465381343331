import { coreApi } from '@modules/core/api';
import { useQuery } from '@tanstack/react-query';

const useGetFeatureFlagsDirectly = (flagKey: string, userId?: string) => {
  const {
    data,
    isLoading,
    error,
    refetch,
    isRefetching,
    isFetched,
  } = useQuery({
    queryKey: ['directFeatureFlag', flagKey],
    queryFn: () => coreApi.getFeatureFlagDirectly(flagKey, userId),
    enabled: !!userId,
    retry: 0,
    initialData: { [flagKey]: { key: 'off' } },
  });

  const isFeatureEnabled = data?.[flagKey]?.key === 'on';

  return {
    data: isFeatureEnabled,
    isLoading,
    error,
    refetch,
    isRefetching,
    isFetched,
  };
};

export default useGetFeatureFlagsDirectly;

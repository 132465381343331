import { Brokerage } from '@modules/documents/api/types';
import {
  OPP_ID_KEY,
  VAULT_TOKEN_KEY,
  LNG_PREFERENCE_KEY,
  PROCESSING_DOCUMENTS
} from '@shared/const';
import { create } from 'zustand';

type AuthStore = {
  token: string | null;
  setToken: (token?: string) => void;
  saveTokenToSessionStorage: (token: string) => void;
  getTokenFromSessionStorage: () => string | null;
  logOut: () => void;
};

type LanguageStore = {
  lng: string | null;
  setLanguage: (lng: string) => void;
  getLanguage: () => string | null;
};

type BrokerageStore = {
  data: Brokerage | null;
  setBrokerage: (data: Brokerage | null) => void;
};

export const useAuthStore = create<AuthStore>((set) => ({
  token: null,
  setToken: (token) => set(() => ({ token })),
  saveTokenToSessionStorage: (token) => {
    sessionStorage.setItem(VAULT_TOKEN_KEY, token);
  },
  getTokenFromSessionStorage: () => sessionStorage.getItem(VAULT_TOKEN_KEY),
  logOut: () => set(() => {
    sessionStorage.removeItem(OPP_ID_KEY);
    sessionStorage.removeItem(VAULT_TOKEN_KEY);
    localStorage.removeItem(PROCESSING_DOCUMENTS);
    return { token: undefined };
  }),
}));

export const useLanguageStore = create<LanguageStore>((set) => ({
  lng: null,
  setLanguage: (lng) => {
    set(() => ({ lng }));
    sessionStorage.setItem(LNG_PREFERENCE_KEY, lng);
  },
  getLanguage: () => sessionStorage.getItem(LNG_PREFERENCE_KEY),
}));

export const useBrokerageStore = create<BrokerageStore>((set) => ({
  data: null,
  setBrokerage: (data) => {
    set(() => ({ data }));
  },
}));

import {
  Button, Flex, Text, PinInput
} from '@huspy/briks-web';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import useGetOpportunityId from '@modules/core/hooks/useGetOpportunityId';
import useOTPTimer from '@modules/core/hooks/useOtpTimer';
import { useRequestOtp } from '@modules/core/hooks/mutations/useRequestOtp';
import { useVerifyOtp } from '@modules/core/hooks/mutations/useVerifyOtp';
import { VaultException } from '@shared/exceptions';

const OTP_LENGTH = 6;

const Resend = ({
  setSession,
  resetOtp,
  isExpired,
}: {
  resetOtp: () => void;
  setSession: (session: string) => void;
  isExpired: boolean
}) => {
  const oppId = useGetOpportunityId();
  const { t } = useTranslation();
  const { timeLeft, isResendEnabled, startTimer } = useOTPTimer(30);
  const { mutateAsync } = useRequestOtp(oppId!, true);
  const handleReset = async () => {
    if (!isResendEnabled) return;
    resetOtp();
    startTimer();
    const { session } = await mutateAsync();
    setSession(session);
  };

  const textToRender = () => {
    if (timeLeft !== 0) {
      return t('auth.verifyOtp.resendIn', { sec: timeLeft });
    }
    if (isExpired) {
      return t('auth.verifyOtp.sendNewCode');
    }
    if (isResendEnabled) {
      return t('auth.verifyOtp.resend');
    }
    return t('auth.verifyOtp.resendIn', { sec: timeLeft });
  };

  return (
    <Button
      variant='ghost'
      disabled={ !isResendEnabled }
      size='md'
      p='1.5'
      ml='1'
    >
      <Text
        data-testid='otp-resend-btn'
        color='neutral.500'
        fontWeight='semibold'
        cursor='pointer'
        onClick={ handleReset }
      >
        {textToRender()}
      </Text>
    </Button>
  );
};

const unBlurLastInput = () => {
  const input = document?.getElementById('pin-input:otp-pin-input:5');
  if (input) {
    input.blur();
  }
};

export const VerifyOtp = ({
  session,
  setSession,
}: {
  session: string | null;
  setSession: (session: string | null) => void;
}) => {
  const { t } = useTranslation();
  const [otp, setOtp] = useState<string[]>([]);
  const oppId = useGetOpportunityId();
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const { mutateAsync, reset: resetApi } = useVerifyOtp(oppId!);

  const isOtpExpired = !!error && !session;

  const isDisabled = otp.filter(Boolean).length !== OTP_LENGTH || !session;

  const onComplete = async (value: string) => {
    if (session) {
      try {
        setIsPending(true);
        await mutateAsync({ otp: value, session });
      } catch (_error) {
        setOtp(new Array(OTP_LENGTH).fill(''));
        unBlurLastInput();
        const errorData = (_error as VaultException)?.data;
        if (errorData?.session) {
          setSession(errorData.session);
          setError(t('auth.verifyOtp.inValidCode'));
        } else {
          setError(t('auth.verifyOtp.tooManyAttempts'));
          setSession(null);
        }
      } finally {
        setIsPending(false);
      }
    }
  };

  const reset = () => {
    resetApi();
    setError(null);
  };

  const resetOtp = () => {
    setOtp(new Array(OTP_LENGTH).fill(''));
    reset();
  };

  return (
    <Flex
      border='1px solid'
      borderColor='neutral.100'
      borderRadius='4'
      p='8'
      gap='4'
      direction='column'
      shadow='shadow-4'
      width='100%'
      maxWidth='500'
    >
      <Text size='5xl' fontWeight='medium'>
        {t('auth.verifyOtp.welcome')}
      </Text>
      <Text size='2xl' fontWeight='medium'>
        {t('auth.verifyOtp.instructions')}
      </Text>
      <Flex w='100%' align='center' justify='center' direction='column' gap='2'>
        <Text
          size='sm'
          alignSelf='self-start'
          color='text-secondary'
        >
          {t('auth.verifyOtp.enterCode')}
        </Text>
        <PinInput
          id='otp-pin-input'
          data-testid='otp-pin-input'
          type='numeric'
          onValueChange={ (value) => {
            setOtp(value.value);
            if (error && !isOtpExpired) {
              reset();
            }
          } }
          value={ otp }
          length={ OTP_LENGTH }
          placeholder=''
          gap='lg'
          size='lg'
          otp
          onValueComplete={ (value) => onComplete(value.valueAsString) }
          invalid={ !!error }
        />
        {error && (
          <Text size='sm' color='text-error-primary'>
            {error}
          </Text>
        )}
      </Flex>
      <Flex align='center'>
        {!isOtpExpired && <Text color='neutral.500'>{t('auth.verifyOtp.error')}</Text>}
        <Resend
          resetOtp={ resetOtp }
          setSession={ setSession }
          isExpired={ isOtpExpired }
        />
      </Flex>
      <Button
        data-testid='otp-verify-btn'
        loading={ isPending }
        disabled={ isDisabled }
        variant={ isDisabled ? 'secondary' : 'primary' }
        onClick={ () => onComplete(otp.join('')) }
      >
        {t('auth.verifyOtp.sendButton')}
      </Button>
      <Flex justify='center' align='center' gap='1'>
        <Text color='neutral.500' size='sm'>
          {t('auth.poweredBy')}
        </Text>
        <Text color='neutral.500' fontWeight='semibold'>
          Client Vault
        </Text>
      </Flex>
    </Flex>
  );
};

import { matchRoutes, useLocation } from 'react-router-dom';

const useGetOpportunityId = () => {
  const location = useLocation();
  const matches = matchRoutes(
    [
      {
        path: '/:oppId',
        label: 'Home',
      },
      {
        path: '/login/:oppId',
        label: 'Login',
      },
      {
        path: '/login/v1/:oppId',
        label: 'Login',
      }
    ],
    location
  );

  return matches?.[0]?.params?.oppId;
};

export default useGetOpportunityId;

import { useEffect, useState } from 'react';

const useOTPTimer = (initialTime: number) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const [isResendEnabled, setIsResendEnabled] = useState(true);

  useEffect(() => {
    if (timeLeft <= 0) {
      setIsResendEnabled(true);
      return;
    }

    const timer = setTimeout(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  const startTimer = () => {
    setTimeLeft(initialTime);
    setIsResendEnabled(false);
  };

  return {
    timeLeft,
    isResendEnabled,
    startTimer,
  };
};

export default useOTPTimer;

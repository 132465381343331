/* eslint-disable no-restricted-syntax */
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from '@huspy/forge/shared';
import { useTranslation } from 'react-i18next';
import { kycApi } from '@modules/kyc/api';
import {
  Address,
  EmploymentKYCData,
  Income,
  KYCCountry,
  Liability,
  Reference,
  ResidencyKYCData,
  UpdateKycData,
} from '@modules/kyc/api/types';
import { VaultFormValues } from '@modules/core/pages/Home/form-context';
import { useState } from 'react';
import { trackAmplitudeEvent } from '@shared/analytics/amplitude';
import { USER_EVENTS } from '@shared/analytics/events';
import { useBrokerageStore } from '@modules/core/store';
import {
  cleanObject, flattenRequestErrors, isValidNumberValue
} from '@modules/kyc/utils';

/**
 * Transforms residential information for KYC submission
 * @param residency Residency data from form
 * @returns Cleaned and transformed residential information
 */
const transformResidentialInformation = (
  residency: ResidencyKYCData
): Partial<ResidencyKYCData> => ({
  ...cleanObject({
    ...residency,
    no_of_months_lived_in_current_residence:
      isValidNumberValue(residency.no_of_months_lived_in_current_residence)
        ? residency.no_of_months_lived_in_current_residence * 12
        : null,
  }),
  reference: cleanObject(residency.reference ?? {}) as Reference,
  address: cleanObject(residency.address ?? {}) as Address,
  address_in_home_country: cleanObject(
    residency.address_in_home_country ?? {}
  ) as Address,
});

/**
 * Transforms employment information for KYC submission
 * @param employment Employment data from form
 * @returns Cleaned employment information
 */
const transformEmploymentInformation = (
  employment: EmploymentKYCData<KYCCountry>
): Partial<EmploymentKYCData<KYCCountry>> => {
  let parsedStartDate: string | null = null;

  if ('start_date' in employment && typeof employment.start_date === 'string') {
    const date = new Date(employment.start_date);
    parsedStartDate = Number.isNaN(date.getTime()) ? null : date.toISOString();
  }

  const companyAddress = 'company_address' in employment
    && typeof employment.company_address === 'object'
    ? cleanObject(employment.company_address ?? {})
    : undefined;

  return {
    ...cleanObject({
      ...employment,
      start_date: parsedStartDate,
    }),
    company_address: companyAddress as Address,
  };
};

/**
 * Transforms income information for KYC submission
 * @param incomes Income data from form
 * @returns Transformed and filtered income list
 */
const transformIncomeInformation = ({
  incomes,
  autoLoan,
  mortgage,
  creditCards,
  personalLoan,
}: {
  incomes: Income[];
  autoLoan: Liability;
  mortgage: Liability;
  creditCards: Liability[];
  personalLoan: Liability;
}): { incomes: Income[]; liabilities: Liability[] } => {
  const transformLiability = (liability: Liability): Liability | null => {
    if (
      liability.type
      && isValidNumberValue(liability.monthly_cost)
      && isValidNumberValue(liability.outstanding_amount)
    ) {
      return liability.external_id
        ? liability
        : ({
          type: liability.type,
          monthly_cost: liability.monthly_cost,
          outstanding_amount: liability.outstanding_amount,
          value: liability.value ?? 0,
          description: liability.description,
        } as Liability);
    }
    return null;
  };
  const transformCreditCard = (creditCard: Liability): Liability | null => {
    if (creditCard.description && isValidNumberValue(creditCard.value)) {
      return creditCard.external_id
        ? creditCard
        : ({
          type: creditCard.type,
          monthly_cost: 0,
          value: creditCard.value,
          description: creditCard.description,
        } as Liability);
    }
    return null;
  };

  const transformIncome = (income: Income): Income | null => {
    if (isValidNumberValue(income.amount)) {
      return income.external_id
        ? income
        : ({
          amount: income.amount,
          type: income.type,
          frequency: income.frequency,
        } as Income);
    }
    return null;
  };

  const liabilities = [
    ...creditCards
      .map((element) => transformCreditCard(element))
      .filter(Boolean),
    transformLiability(mortgage),
    transformLiability(autoLoan),
    transformLiability(personalLoan)
  ].filter(Boolean) as Liability[];

  const transformedIncomes = incomes
    .map((element) => transformIncome(element))
    .filter(Boolean) as Income[];

  return { incomes: transformedIncomes, liabilities };
};

/**
 * Custom hook for updating KYC data
 * @param opportunityId Unique identifier for the opportunity
 * @returns Mutation hook for updating KYC data
 */
const useUpdateKYCData = <TCountry extends KYCCountry = 'AE'>(
  opportunityId: string
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [error, setError] = useState({});
  const { data: brokerage } = useBrokerageStore();

  const { mutate, mutateAsync, isPending } = useMutation({
    mutationKey: ['updateKyc', opportunityId],
    mutationFn: async (data: VaultFormValues<TCountry>) => {
      setError({});
      trackAmplitudeEvent(USER_EVENTS.FORM_FIELDS.VAULT_FIELDS_SAVE_ATTEMPTED, {
        brokerage_external_id: brokerage?.external_id,
        brokerage_name: brokerage?.name,
        case_id: opportunityId,
      });
      const updatePromises = data.applicants.map(async (applicant) => {
        const body: UpdateKycData<KYCCountry> = {
          personal_information: cleanObject(applicant.personal_information),
          residential_information: transformResidentialInformation(
            applicant.residential_information
          ),
          employment_information: transformEmploymentInformation(
            applicant.employment_information
          ),
          financial_information: transformIncomeInformation({
            incomes: applicant.incomes,
            autoLoan: applicant.autoLoan,
            mortgage: applicant.mortgage,
            creditCards: applicant.creditCards,
            personalLoan: applicant.personalLoan,
          }),
        };

        return kycApi.updateKYCData<TCountry>(
          opportunityId,
          applicant.applicantId,
          body
        );
      });

      return Promise.allSettled(updatePromises);
    },
    onSuccess: (responses) => {
      const requestErrors = flattenRequestErrors(responses);
      setError(requestErrors);

      const hasErrors = responses.some((res) => res.status === 'rejected');

      queryClient.invalidateQueries({ queryKey: ['vaultProgress', opportunityId] });
      queryClient.invalidateQueries({ queryKey: ['kyc', opportunityId] });
      if (hasErrors) {
        trackAmplitudeEvent(USER_EVENTS.FORM_FIELDS.VAULT_FIELDS_SAVE_FAILED, {
          brokerage_external_id: brokerage?.external_id,
          brokerage_name: brokerage?.name,
          case_id: opportunityId,
          error: responses
            .filter((res) => res.status === 'rejected')
            .map((res) => res.reason?.data),
        });
        throw new Error(t('kyc.notifications.kycUpdateFailed'));
      } else {
        trackAmplitudeEvent(USER_EVENTS.FORM_FIELDS.VAULT_FIELDS_SAVE_COMPLETED, {
          brokerage_external_id: brokerage?.external_id,
          brokerage_name: brokerage?.name,
          case_id: opportunityId,
        });
        toast('info', { message: t('kyc.notifications.kycUpdated') });
      }
    },
    onError: () => {
      toast('error', { message: t('kyc.notifications.kycUpdateFailed') });
    },
  });

  return {
    mutate,
    mutateAsync,
    error,
    isPending,
  };
};

export default useUpdateKYCData;
